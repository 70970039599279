<template>
  <div class="book-main">
    <div class="container book-block">
      <nav class="breadcrumbs-block">
        <ul class="breadcrumbs-list">
          <li class="breadcrumbs-item"><router-link to="/">{{ $t('navbar.home') }}</router-link></li>
          <li class="breadcrumbs-item">Библиотека</li>
        </ul>
      </nav>
      <h3>Библиотека</h3>
      <div class="book-main-title">
        <router-link tag="a" to="/library">
          <img src="/images/CaretCircleLeft.svg" width="65" height="65">
        </router-link>
        <p>{{formattedAuthors}} — {{bookInfo[`name_${$i18n.locale}`] ||bookInfo.name_ru}}</p>
      </div>

      <div class="book-main-wrapper">
        <div class="book-main-left">
          <div class="book-main-carousel">
<!--            <carousel :perPage="3" :loop="true" :autoplay="false" :autoplayTimeout="5000" :navigationEnabled="true"-->
<!--                      :paginationEnabled="false" class="carousel-main-mobile">-->
<!--              <slide v-for="(slide, index) in slides" class="current-review-card-mobile">-->
<!--                <div style="padding: 10px">-->
<!--                  <div class="card-top">-->
<!--                    <div class="card-img" :class="{'active-page': activePage == index}" @click="activePage = index">-->
<!--                      <el-image-->
<!--                          style="width: 100%; height: 100%"-->
<!--                          src="/images/book-1.png"-->
<!--                          fit="fit"></el-image>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </slide>-->
<!--            </carousel>-->
            <div class="book-main-carousel-new">
              <div class="book-main-carousel-wrapper" ref="slider" v-viewer="{movable: false}">
                <div class="book-main-carousel-slide" v-for="(slide, index) in bookInfo.images" @click="activePage = index" :class="{'active-page': activePage == index}">
                  <el-image
                      style="width: 100%; height: 100%"
                      :src="bookUrl + slide.image"
                      fit="fit"></el-image>
                </div>
              </div>
              <div class="book-main-carousel-nav">
                <div class="book-main-carousel-next" @click="prevSlide">
<!--                  <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M4.5 15.4718C4.49968 15.3026 4.53692 15.1354 4.60903 14.9824C4.68114 14.8293 4.78633 14.6942 4.917 14.5868L9.336 10.1768C9.54694 9.96609 9.83287 9.84775 10.131 9.84775C10.4291 9.84775 10.7151 9.96609 10.926 10.1768C11.0302 10.281 11.1129 10.4047 11.1693 10.5408C11.2257 10.677 11.2548 10.8229 11.2548 10.9703C11.2548 11.1176 11.2257 11.2636 11.1693 11.3997C11.1129 11.5359 11.0302 11.6596 10.926 11.7638L8.349 14.3468L24.375 14.3468C24.6734 14.3468 24.9595 14.4653 25.1705 14.6763C25.3815 14.8872 25.5 15.1734 25.5 15.4718C25.5 15.7701 25.3815 16.0563 25.1705 16.2673C24.9595 16.4782 24.6734 16.5968 24.375 16.5968L8.334 16.5968L10.9335 19.1798C11.0379 19.284 11.1208 19.4078 11.1774 19.5441C11.2339 19.6804 11.263 19.8265 11.263 19.974C11.263 20.1216 11.2339 20.2677 11.1774 20.404C11.1208 20.5402 11.0379 20.664 10.9335 20.7683C10.829 20.8728 10.705 20.9557 10.5684 21.0123C10.4319 21.0689 10.2855 21.098 10.1378 21.098C9.98995 21.098 9.8436 21.0689 9.70706 21.0123C9.57053 20.9557 9.44647 20.8728 9.342 20.7683L4.83 16.2623C4.72469 16.1594 4.64119 16.0364 4.58449 15.9006C4.52779 15.7648 4.49906 15.6189 4.5 15.4718Z" fill="#185CFF"/>-->
<!--                  </svg>-->
                  ◀
                </div>
                <div class="book-main-carousel-next" @click="nextSlide">
<!--                  <svg width="30" height="31" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M17 10.25C17.0002 10.1372 16.9754 10.0258 16.9273 9.92377C16.8792 9.82174 16.8091 9.73165 16.722 9.66001L13.776 6.72001C13.6354 6.57956 13.4448 6.50067 13.246 6.50067C13.0472 6.50067 12.8566 6.57956 12.716 6.72001C12.6465 6.78948 12.5914 6.87195 12.5538 6.96271C12.5162 7.05348 12.4968 7.15077 12.4968 7.24901C12.4968 7.34726 12.5162 7.44455 12.5538 7.53532C12.5914 7.62608 12.6465 7.70855 12.716 7.77801L14.434 9.50001L3.75 9.50001C3.55109 9.50001 3.36032 9.57903 3.21967 9.71969C3.07902 9.86034 3 10.0511 3 10.25C3 10.4489 3.07902 10.6397 3.21967 10.7803C3.36032 10.921 3.55109 11 3.75 11L14.444 11L12.711 12.722C12.6414 12.7915 12.5861 12.874 12.5484 12.9649C12.5107 13.0557 12.4913 13.1531 12.4913 13.2515C12.4913 13.3499 12.5107 13.4473 12.5484 13.5381C12.5861 13.629 12.6414 13.7115 12.711 13.781C12.7807 13.8507 12.8634 13.906 12.9544 13.9437C13.0454 13.9814 13.143 14.0009 13.2415 14.0009C13.34 14.0009 13.4376 13.9814 13.5286 13.9437C13.6196 13.906 13.7023 13.8507 13.772 13.781L16.78 10.777C16.8502 10.7085 16.9059 10.6265 16.9437 10.5359C16.9815 10.4454 17.0006 10.3481 17 10.25Z" fill="#185CFF"/>-->
<!--                  </svg>-->
                  ▶
                </div>
              </div>
            </div>
            <div class="book-main-carousel-title">
              {{formattedAuthors}} — {{bookInfo[`name_${$i18n.locale}`] ||bookInfo.name_ru}}
            </div>
          </div>

          <div class="line-top"></div>

          <div class="book-chapter-title">
            Краткое содержание
          </div>
          <div class="book-chapter-text">
            {{bookInfo[`short_about_${$i18n.locale}`] || bookInfo.short_about_ru}}
          </div>

          <div class="line-top"></div>
          <div class="book-chapter-title">
            Особенности и преимущества
          </div>
          <div class="book-chapter-text">
            {{bookInfo[`advantages_${$i18n.locale}`] || bookInfo.advantages_ru}}
          </div>
        </div>
        <div class="book-main-right">
          <div class="book-author">

            <carousel :perPage="1" :loop="true" :autoplay="false" :autoplayTimeout="5000" :navigationEnabled="true"
                      :paginationEnabled="false" class="carousel-book-authors" v-if="bookInfo.authors.length > 1">
              <slide v-for="(slide, index) in bookInfo.authors">
                <div class="d-flex align-items-center" style="gap: 25px">
                  <div class="book-author-img">
                    <el-image
                        style="width: auto; height: 80px;"
                        :src="bookUrl + slide.image"
                        fit="cover"></el-image>
                  </div>
                  <div>
                    <div class="book-author-name">
                      {{slide[`name_${$i18n.locale}`] || slide.name_ru}}
                    </div>
                    <div class="book-author-job">
                      {{slide[`position_${$i18n.locale}`] || slide.position_ru}}
                    </div>
                  </div>
                </div>
              </slide>
            </carousel>


            <div v-else class="d-flex align-items-center" style="gap: 25px">
              <div class="book-author-img">
                <el-image
                    style="width: auto; height: 80px;"
                    :src="bookUrl + bookInfo.authors[0].image"
                    fit="cover"></el-image>
              </div>
              <div>
                <div class="book-author-name">
                  {{bookInfo.authors[0][`name_${$i18n.locale}`] || bookInfo.authors[0].name_ru}}
                </div>
                <div class="book-author-job">
                  {{bookInfo.authors[0][`position_${$i18n.locale}`] || bookInfo.authors[0].position_ru}}
                </div>
              </div>
            </div>


            <div class="book-author-text">
              {{bookInfo.authors[0][`description_${$i18n.locale}`] || bookInfo.authors[0].description_ru}}
            </div>
          </div>

          <div class="book-structure">
            <div class="line-top"></div>
            <div class="book-chapter-title">
              Структура и содержание
            </div>
            <ol class="book-structure-list">
              <li v-for="item in bookInfo.contents">
                <span style="color: #0CACDE">{{item[`name_${$i18n.locale}`] || item.name_ru}}</span><br>
                {{item[`description_${$i18n.locale}`] || item.description_ru}}
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="book-btns-block">
        <button class="book-el-btn" @click="buyElBook = true">
          <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0273 21.8879C9.69141 25.8606 4.39453 25.8606 4.39453 25.8606C4.39453 25.8606 4.39453 20.5637 8.36719 19.2278" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.957 12.6061L15 20.5631L9.69141 15.2545L17.6484 7.2975C20.6602 4.28578 23.6719 4.32094 24.9609 4.50844C25.1598 4.53497 25.3445 4.62623 25.4864 4.76813C25.6283 4.91003 25.7196 5.09468 25.7461 5.29359C25.9336 6.58266 25.9687 9.59438 22.957 12.6061Z" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.6328 13.9309V21.5012C21.6293 21.7485 21.5282 21.9844 21.3516 22.1575L17.5664 25.9543C17.4463 26.0743 17.2959 26.1594 17.1313 26.2006C16.9666 26.2417 16.7938 26.2374 16.6314 26.1881C16.469 26.1388 16.3231 26.0463 16.2091 25.9205C16.0951 25.7947 16.0175 25.6404 15.9844 25.4739L15 20.5637" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.3245 8.62231H8.75419C8.5069 8.62584 8.27103 8.72693 8.09794 8.90356L4.30106 12.6887C4.18111 12.8088 4.09602 12.9592 4.05485 13.1239C4.01368 13.2885 4.01799 13.4613 4.06731 13.6237C4.11664 13.7861 4.20912 13.9321 4.33491 14.046C4.46069 14.16 4.61506 14.2377 4.78153 14.2708L9.69169 15.2551" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Купить электронную версию - {{ bookInfo.price | currency }}
        </button>
        <button class="book-paper-btn" @click="buyPaperBook = true">
          Купить бумажную версию - {{ bookInfo.price | currency }}
        </button>
      </div>
    </div>

    <!-- Модалка электронной версии (Перенести в одну) -->
    <el-dialog
        :visible.sync="buyElBook"
        :width="modalWidth"
        center>
      <div class="modal-book-title">
        <img src="/images/books-icon.svg" width="52" height="52"/>
        <div>
          <p class="modal-book-subtitle">
            Покупка электронной версии
          </p>
        </div>
      </div>

      <div class="modal-book-card">
        <img :src="bookUrl + bookInfo.images[0].image" width="133" height="200"/>
        <div class="price-tag">
          {{ bookInfo.price | currency }}
        </div>
        <div class="book-card-desc">
          {{ formattedAuthors }} — {{ bookInfo[`name_${$i18n.locale}`] || bookInfo.name_ru }}
        </div>
      </div>

      <div>
        <button @click="closeModal" class="btn-cancel">
          Отмена
        </button>
        <button  @click="purchase" class="btn-buy">
          Оплата
        </button>
      </div>
    </el-dialog>

    <!-- Модалка печатной версии (Перенести в одну) -->
    <el-dialog
        :visible.sync="buyPaperBook"
        :width="modalWidth"
        center>
      <div class="modal-book-title">
        <img src="/images/books-icon.svg" width="52" height="52"/>
        <div>
          <p class="modal-book-subtitle">
            Покупка бумажной версии
          </p>
        </div>
      </div>

      <div class="modal-book-card">
        <img :src="bookUrl + bookInfo.images[0].image" width="133" height="200"/>
        <div class="price-tag">
          {{ bookInfo.price | currency }}
        </div>
        <div class="book-card-desc">
          {{ formattedAuthors }} — {{ bookInfo[`name_${$i18n.locale}`] || bookInfo.name_ru }}
        </div>
      </div>

      <div>
        <button @click="closeModal" class="btn-cancel">
          Отмена
        </button>
        <button @click="showDeliveryForm" class="btn-buy">
          Оплата
        </button>
      </div>
    </el-dialog>

    <!-- Форма для скачивания -->
    <el-dialog
        :visible.sync="downloadSelectedBook"
        :width="modalWidth"
        center
    >
      <div class="modal-book-title">
        <div>
          <p class="modal-book-subtitle">
            Нажмите чтобы скачать файл
          </p>
        </div>
      </div>
      <div class="download-book-img">
        <img :src="selectedBook ? bookUrl + selectedBook.images[0].image : null" width="224" height="335"/>
      </div>

      <button @click="download" class="download-book-link">
        <div class="d-flex flex-column">
          <span class="text-truncate">{{ selectedBook ? selectedBook[`name_${$i18n.locale}`] || selectedBook.name_ru : null }}</span>
          <span>{{ selectedBook ? selectedBook.file_size : '0 KB' }}</span>
        </div>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35Z"
              stroke="white" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M14.6953 20.9468L19.9986 26.2501L25.3019 20.9468" stroke="white" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M20 13.75V26.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </el-dialog>

    <!-- Форма доставки -->
    <el-dialog
        :visible.sync="sendingSelectedPaperBook"
        :width="modalWidth"
        center
    >
      <div class="modal-book-title">
        <div>
          <p class="modal-book-subtitle">
            {{ $t('purchasePaperVersion') }}
          </p>
          <p class="modal-book-text">
            {{ formattedAuthors }} — {{ bookInfo[`name_${$i18n.locale}`] }}
          </p>
        </div>
      </div>
      <div class="modal-paper-book-card">
        <el-image
            v-if="bookInfo.images"
            style="width: 77px; height: 116px"
            :src="bookUrl + bookInfo.images[0].image"
            fit="fit"
            @error="handleImageError"
        />
        <div class="d-flex flex-column align-items-center">
          <div class="book-title">
            {{ bookInfo[`short_about_${$i18n.locale}`] }}
          </div>
          <div class="book-price">
            {{ bookInfo.price | currency }}
          </div>
        </div>
      </div>

      <div class="sending-list-data">
        <div class="d-flex align-items-center">
          <div class="sending-column-data">
            Почтовый индекс *
          </div>
          <el-input v-model="deliveryData.postal_code"></el-input>
        </div>

        <div class="demarcation-line"></div>

        <div class="d-flex align-items-center">
          <div class="sending-column-data">
            Область *
          </div>
          <el-select
              :placeholder="$t('references.region-placeholder')"
              :loading="regions.loading"
              v-model="deliveryData.region_id"
              filterable
          >
            <el-option
                v-for="item in regions.data"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="d-flex align-items-center">
          <div class="sending-column-data">
            Район *
          </div>
          <el-select
              :placeholder="$t('references.region-placeholder')"
              :loading="cities.loading"
              :disabled="cities.disabled"
              v-model="deliveryData.city_id"
              filterable
          >
            <el-option
                v-for="item in cities.data"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="d-flex align-items-center">
          <div class="sending-column-data">
            Населенный пункт *
          </div>
          <el-select
              :placeholder="$t('references.district-placeholder')"
              :loading="localities.loading"
              :disabled="localities.disabled"
              v-model="deliveryData.locality_id"
              filterable
          >
            <el-option
                v-for="item in localities.data"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="d-flex align-items-center">
          <div class="sending-column-data">
            Адрес *
          </div>
          <el-input v-model="deliveryData.address"></el-input>
        </div>

        <div class="demarcation-line"></div>

        <div class="d-flex align-items-center">
          <div class="sending-column-data">
            ФИО*<br>
            (на русском)
          </div>
          <el-input v-model="deliveryData.full_name"></el-input>
        </div>
        <div class="d-flex align-items-center">
          <div class="sending-column-data">
            Контактный телефон *
          </div>
          <el-input v-model="deliveryData.phone"></el-input>
        </div>
        <div class="d-flex align-items-center">
          <div class="sending-column-data">
            E-mail *
          </div>
          <el-input v-model="deliveryData.email"></el-input>
        </div>

        <div class="demarcation-line"></div>

        <div class="btns-paper-book">
          <button @click="buyElBook = false" class="btn-cancel">
            Отменить
          </button>
          <button class="btn-buy" @click="purchase">
            Подтвердить покупку
          </button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';
import Vue from 'vue';
import md5 from "@/plugins/md5";

Vue.use(VueViewer)
export default {
  name: "Book",
  data() {
    return {
      slides: [
        {image: "/images/book-1.png", },
        {image: "/images/book-2.png", },
        {image: "/images/book-1.png", },
        {image: "/images/book-2.png", },
        {image: "/images/book-1.png", },
        {image: "/images/book-2.png", },
      ],
      activePage: 0,
      buyElBook: false,
      buyPaperBook: false,
      modalWidth: "440px",
      bookInfo: {
        authors: [],
      },
      bookUrl: window.API_ROOT + '/storage/',
      sendingSelectedPaperBook: false,
      downloadSelectedBook: false,
      downloadLink: null,
      deliveryData: {
        postal_code: null,
        region_id: null,
        city_id: null,
        locality_id: null,
        address: null,
        full_name: null,
        phone: null,
        email: null,
      },
      currentType: null,
      regions: {
        loading: false,
        data: [],
      },
      cities: {
        loading: false,
        disabled: true,
        data: [],
      },
      localities: {
        loading: false,
        disabled: true,
        data: [],
      },
      selectedBook: null,
    }
  },
  methods: {
    nextSlide() {
      if (this.activePage == this.bookInfo.images.length - 1) {
        return
      }
      this.$refs.slider.scrollLeft += 140;
      this.activePage = this.activePage + 1
    },
    prevSlide() {
      if (this.activePage == 0) {
        return
      }
      this.$refs.slider.scrollLeft -= 140;
      this.activePage = this.activePage - 1
    },
    handleImageError() {
      console.log('Error');
    },
    closeModal() {
      this.buyElBook = false;
      this.buyPaperBook = false;
    },
    getRegions() {
      this.regions.loading = true;
      this.$http.get(`${window.API_ROOT}/api/references/regions`)
          .then((res) => {
            if (res.body.data) {
              this.regions.data = res.body.data;
            }
            this.regions.loading = false;
          })
          .catch((e) => {
            this.regions.loading = false;
          });
    },
    getCities(regionId) {
      this.cities.loading = true;
      this.$http.get(`${window.API_ROOT}/api/references/districts/${regionId}`)
          .then((res) => {
            if (res.body.data) {
              this.cities.data = res.body.data;
            }
            this.cities.loading = false;
          })
          .catch((e) => {
            this.cities.loading = false;
          });
    },
    getLocalities(cityId) {
      this.localities.loading = true;
      this.$http.get(`${window.API_ROOT}/api/references/localities/${cityId}`)
          .then((res) => {
            if (res.body.data) {
              this.localities.data = res.body.data;
            }
            this.localities.loading = false;
          })
          .catch((e) => {
            this.localities.loading = false;
          });
    },
    showDeliveryForm() {
      this.closeModal();
      this.sendingSelectedPaperBook = true;
    },
    async purchase() {
      const login = 'book1';
      const password = 'wQSCZEwg3qjiY8R10bm9';
      const lastInvoice = await this.$http.get(`${window.API_ROOT}/api/library/delivery/last-invoice`);
      const invId = lastInvoice.body.invoice;
      const outSum = parseInt(this.bookInfo.price).toString();
      const signature = md5(`${login}:${outSum}:${invId}:${password}`);

      localStorage.setItem('lastBoughtBook', JSON.stringify({
        ...this.deliveryData,
        bookId: this.bookInfo.id,
        type: this.currentType,
      }));

      window.open(`https://auth.robokassa.kz/Merchant/Index.aspx?MerchantLogin=${login}&OutSum=${outSum}&InvoiceID=${invId}&SignatureValue=${signature}`);
    },
    download() {
      window.open(this.downloadLink);
      this.downloadSelectedBook = false;
    },
  },
  computed: {
    formattedAuthors() {
      return this.bookInfo.authors
          .map(author => author[`name_${this.$i18n.locale}`])
          .join(", ");
    },
  },
  watch: {
    'deliveryData.region_id'(id) {
      this.getCities(id);
      this.cities.disabled = false;
      this.deliveryData.city_id = null;
      this.deliveryData.locality_id = null;
    },
    'deliveryData.city_id'(id) {
      if (id) {
        this.getLocalities(id);
      }
      this.localities.disabled = false;
      this.deliveryData.locality_id = null;
    }
  },
  mounted() {
    const { query } = this.$route;
    const id = this.$route.params.id;

    this.$http.get(window.API_ROOT + `/api/library/${id}`)
        .then(res => {
          this.bookInfo = res.body.data;
        });

    const uuid = localStorage.getItem('bookUuid');
    if (query.type === 'ONLINE' && uuid) {
      const lastBoughtData = JSON.parse(localStorage.getItem('lastBoughtBook'));
      this.$http.get(window.API_ROOT + `/api/library/${lastBoughtData.bookId}`).then(res => {
        this.selectedBook = res.body.data;
        this.downloadSelectedBook = true;
        this.downloadLink = `${window.API_ROOT}/api/library/download/${uuid}`;
        localStorage.removeItem('bookUuid');
        localStorage.removeItem('lastBoughtBook');
      });
    }

    this.getRegions();
  }
}

</script>

<style>

.book-block {
  padding-top: 75px;
}

.book-main {
  position: relative;
  padding-bottom: 100px;
}

.book-main::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 500px;
  background: linear-gradient(to right, #32436D, #1A84B2, #02C7FA);
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 20%;
  z-index: -1;
}

.book-main::after {
  position: absolute;
  content: "";
  width: 300px;
  height: 420px;
  right: -60px;
  bottom: 270px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/images/library-back.png");
}

.book-block h3 {
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #FFF;
}

.book-main-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: #FFF;
  font-family: "Roboto", sans-serif;
  display: flex;
  gap: 25px;
  margin-bottom: 30px;
}

.book-main-wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.book-main-wrapper::after {
  position: absolute;
  content: "";
  width: 343px;
  height: 343px;
  top: -14px;
  right: -350px;
  background-image: url("/images/books-img.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.book-main-left {
  width: 49%;
}

.book-main-right {
  width: 49%;
}

.book-main-carousel {
  width: 100%;
  max-width: 581px;
  background: rgba(231, 244, 250, 1);
  padding: 10px 30px;
  border-radius: 20px;
  margin-bottom: 36px;
}

.book-main-carousel-title {
  color: rgba(71, 84, 103, 1);
  font-weight: 600;
  font-size: 19px;
  line-height: 36px;
  font-family: "Roboto", sans-serif;
  margin-top: 30px;

}

.book-main-carousel .VueCarousel-navigation-prev {
  color: #32436D !important;
  background: #C9DCEC;
  width: 110px;
  height: 60px;
  background-image: none;
  position: absolute;
  left: 230px !important;
  top: 330px;
  cursor: pointer;
  border-radius: 14px;
}

.book-main-carousel .VueCarousel-navigation-next {
  color: #32436D !important;
  background: #C9DCEC;
  width: 110px;
  height: 60px;
  background-image: none;
  position: absolute;
  left: 150px !important;
  top: 330px;
  cursor: pointer;
  border-radius: 14px;
}

.carousel-book-authors .VueCarousel-navigation-next {
  color: #32436D !important;
  font-size: 0;
  background: #C9DCEC !important;
  width: 48px;
  height: 40px;
  background-repeat: no-repeat !important;
  background-size: 24px 24px !important;
  background-position: center center !important;
  background-image: url(/images/arrow-mobile-right.svg) !important;
  position: absolute !important;
  right: 30px !important;
  top: 0px !important;
  cursor: pointer;
  border-radius: 14px;
}

.carousel-book-authors .VueCarousel-navigation-prev {
  color: #32436D !important;
  font-size: 0;
  background: #C9DCEC !important;
  width: 48px;
  height: 40px;
  background-repeat: no-repeat !important;
  background-size: 24px 24px !important;
  background-position: center center !important;
  background-image: url(/images/arrow-mobile-left.svg) !important;
  position: absolute !important;
  left: calc(100% - 45px) !important;
  top: 0px !important;
  cursor: pointer;
  border-radius: 14px;
}


.book-chapter-title {
  color: #32436D;
  font-size: 36px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  margin: 25px 0;
}

.book-chapter-text {
  font-size: 17px;
  font-weight: 400;
  line-height: 27px;
  font-family: "Roboto", sans-serif;
  color: rgba(84, 84, 84, 1);
  margin: 25px 0;
}

.book-author {
  background-color: #FFF;
  border-radius: 20px;
  padding: 35px;
  box-shadow: 0 4px 12px 0 rgba(12, 68, 204, 0.1);
}

.book-author-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}

.book-author-name {
  color: rgba(50, 67, 109, 1);
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  font-family: "Manrope", sans-serif;
}

.book-author-job {
  color: rgba(148, 151, 161, 1);
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  font-family: "Manrope", sans-serif;
}

.book-author-text {
  margin-top: 25px;
  color: rgba(50, 67, 109, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  font-family: "Open Sans", sans-serif;
}

.book-structure {
  margin-top: 50px;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 4px 12px 0 rgba(12, 68, 204, 0.1);
}

.book-structure-list {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  font-family: "Roboto", sans-serif;
  padding-left: 20px;
}

.book-structure-list li {
  margin-bottom: 20px;
}

.book-structure-list li::marker {
  color: #0CACDE;
}

.book-el-btn {
  border: 1.33px solid rgba(250, 132, 67, 1);
  color: #FFF;
  border-radius: 13px;
  padding: 12px 40px;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  background-color: rgba(250, 132, 67, 1);
  transition: transform 0.5s ease;
}

.book-paper-btn {
  border: 1.33px solid rgba(16, 144, 203, 1);
  color: #FFF;
  border-radius: 13px;
  padding: 12px 40px;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  background-color: rgba(16, 144, 203, 1);
  transition: transform 0.5s ease;
}

.book-paper-btn:not([disabled]):hover,
.book-el-btn:not([disabled]):hover {
  transform: scale(1.05);
}

.book-el-btn[disabled],
.book-paper-btn[disabled] {
  opacity: .7;
}

.book-btns-block {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 50px;
}

.book-main-carousel-wrapper {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  transition: 0.5s;
  scroll-behavior: smooth;
  padding: 0 140px;
}

.book-main-carousel-wrapper::-webkit-scrollbar {
  height: 0px;
}

.book-main-carousel-slide {
  min-width: 120px;
  height: 190px;
}

.active-page {
  height: 270px;
  min-width: 190px;
}

.book-main-carousel-next {
  height: 60px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(240, 249, 255, 1);
  border-radius: 20px;
}

.book-main-carousel-nav {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.book-main-wrapper .VueCarousel-inner {
  align-items: flex-end;
}

.modal-book-title {
  display: flex;
  gap: 16px;
}

.modal-book-title div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}


.modal-book-title p {
  margin: 0;
  padding: 0;
}

.modal-book-card {
  margin: 30px auto;
  width: 300px;
  border: 2px solid rgba(16, 144, 203, 1);
  border-radius: 12px;
  padding: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.modal-book-card::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  top: 16px;
  right: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/images/checkbox-tick.svg");
}

.price-tag {
  color: rgba(50, 67, 109, 1);
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  font-family: "Inter", sans-serif;
}

.book-card-desc {
  color: rgba(50, 67, 109, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: "Inter", sans-serif;
  word-break: normal;
}

.btn-cancel {
  width: 100%;
  border: 1px solid rgba(208, 213, 221, 1);
  border-radius: 8px;
  padding: 10px 18px;
  color: rgba(50, 67, 109, 1);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-family: "Inter", sans-serif;
}

.btn-buy {
  width: 100%;
  border: 1px solid rgba(208, 213, 221, 1);
  border-radius: 8px;
  padding: 10px 18px;
  color: #FFF;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-family: "Inter", sans-serif;
  background: rgba(16, 144, 203, 1);
  margin-top: 10px;
}

.modal-book-subtitle {
  color: rgba(50, 67, 109, 1);
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  font-family: "Inter", sans-serif;
}

.modal-book-text {
  color: rgba(50, 67, 109, 1);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
}

.download-book-img {
  margin-top: 40px;
  margin-bottom: 23px;
  text-align: center;
}

.download-book-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(16, 144, 203, 1);
  color: #FFF;
  text-decoration: none !important;
  padding: 20px;
  width: 100%;
  border-radius: 12px;
  gap: 8px;
  border: none;
  text-align: left;
}

.download-book-link:hover {
  color: #FFF;
}

.modal-paper-book-card {
  display: flex;
  gap: 32px;
  padding: 15px;
  border: 1.5px solid rgba(16, 144, 203, 1);
  border-radius: 7px;
  max-width: 250px;
  margin: 20px 0;
}

.modal-paper-book-card .book-title {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  font-family: "Inter", sans-serif;
  color: rgba(71, 84, 103, 1);
  word-break: normal;
  letter-spacing: 0px;
}

.modal-paper-book-card .book-price {
  font-weight: 600;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  color: rgba(50, 67, 109, 1);
  margin-top: 18px;
}

.sending-column-data {
  min-width: 200px;
}

.sending-list-data {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.demarcation-line {
  width: 100%;
  height: 1px;
  background: rgba(228, 231, 236, 1);
}

.btns-paper-book {
  display: flex;
  gap: 15px;
}

.btns-paper-book .btn-buy {
  margin-top: 0;
}

.library-filter-author .el-input__inner,
.library-filter-year .el-input__inner,
.library-filter-chapter .el-input__inner {
  color: #FFF;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.5px;
}
</style>
